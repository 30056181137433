import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {FolderOpenTwoTone, FolderTwoTone} from '@ant-design/icons';
import {getAssetsByOwnerAndGroup} from '../../utils/network/folderReader';
import {ResponseApiBulk} from '../../models/ResponseApi';
import {Asset} from '../../models/Asset';
import LinkUpload from '../LinkUpload/LinkUpload';
import {useUserProfile} from '../../hooks/UseUserProfile';
const {Sider} = Layout;


// eslint-disable-next-line require-jsdoc
export class SideBarProps {
  onAssetsLoad!: (assets: Asset[], cursor: string) => void;
}

const Sidebar = (props:SideBarProps) => {
  const [isRootFolderOpen, setIsRootFolderOpen] = useState<boolean>(false);
  const profile = useUserProfile();

  const onAssetsLoad = async (groupId: string) => {
    const {data, cursor}: ResponseApiBulk<Asset> =
      await getAssetsByOwnerAndGroup(profile.email || '', '0');
    setIsRootFolderOpen(!isRootFolderOpen);
    props.onAssetsLoad(data || [], cursor || '');
  };
  return (
    <Sider style={{background: 'white'}}>
      <Menu style={{
        background: 'white',
        border: '0',
        padding: '0,0,0',
        display: 'flex',
        margin: '0',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
      }}>
        <Menu.Item
          onClick={() => onAssetsLoad('0')}
          key="1">
          {isRootFolderOpen?<FolderOpenTwoTone/>:<FolderTwoTone/>
          }
          <span style={{alignItems: 'baseline',
            width: '0'}}> Root </span>
        </Menu.Item>
        <div data-testid='uploadAssetIcon'>
          <LinkUpload/>
        </div>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
