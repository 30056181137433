import axios from 'axios';
import {GetDownloadResponse} from './fileDownload.models';
import * as Constants from '../../../utils/constants';

const domain = Constants.beServer;
export const downloadFile =
  async (assetId: string): Promise<GetDownloadResponse> => {
    return axios.get(domain+`/v1/assets/${assetId}/downloadUrl`)
        .then((urlResponse) => urlResponse.data);
  };
