import axios from 'axios';
import {ResponseApiBulk} from '../../models/ResponseApi';
import {Asset} from '../../models/Asset';
import * as Constants from '../../utils/constants';
const domain = Constants.beServer;
export const getFolders = (): Array<string> => {
  return ['Carpeta 1', 'Carpeta 2', 'Carpeta 3', 'Eric'];
};

export const getAssetsByGroup =
  (groupId: string): Promise<ResponseApiBulk<Asset>> => {
    return axios.get(domain+'/v1/assets/groups/'+groupId)
        .then((value) => value.data);
  };

export const getAssetsByOwnerAndGroup =
  (ownerId: string, groupId: string, cursor?: string)
    : Promise<ResponseApiBulk<Asset>> => {
    const cursorParam = cursor?'?cursor='+cursor:'';
    return axios
        .get(
            domain+'/v1/assets/users/'+ownerId+'/groups/'+groupId + cursorParam,
        )
        .then((value) => value.data);
  };
