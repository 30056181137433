/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Progress, Spin} from 'antd';
import {Modal} from 'antd';
import {Upload} from 'antd';
import {
  createAsset,
  getUploadUrl,
} from '../../utils/network/fileUpload/fileUpload';
import {uploadToS3} from '../../utils/network/s3/s3';
import {useUserProfile} from '../../hooks/UseUserProfile';
import {RcFile, UploadProps} from 'antd/lib/upload/interface';

const LinkUpload = (props: any) => {
  const [userId, setUserId] = useState<string>('');
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadingAssets, setIsUploadingAssets] = React.useState(false);
  const [isButtonEnable, setIsButtonEnable] = useState(false);
  const [assetUploadedPercentage, setAssetUploadedPercentage] = useState(0);
  // const [uploadProgress, setUploadProgress] = useState({
  //   current: 0,
  //   total: 0,
  // });
  const profileTemp = useUserProfile();
  useEffect(() => {
    setUserId(profileTemp.email || '');
  }, [profileTemp]);
  const showModal = () => {
    setIsModalVisible(true);
    setIsButtonEnable(false);
  };
  const handleCancel = () => {
    setFilesToUpload([]);
    setIsModalVisible(false);
    setIsUploadingAssets(false);
    setAssetUploadedPercentage(0);
    // setUploadProgress({
    //   current: 0,
    //   total: 0,
    // });
  };

  const handleOk = async () => {
    setIsButtonEnable(false);
    setIsUploadingAssets(true);

    const filesPromises: Array<Promise<string>> = [];

    console.log('files to upload: ' + filesToUpload.length);
    // console.log('uploaded files' + uploadedFiles);
    // setUploadProgress({
    //   current: 0,
    //   total: filesToUpload.length * 3,
    // });
    const filesMap = new Map<string, number>();
    filesToUpload.map((file) => {
      filesMap.set(file.name, 0);
      const singleAssetUpload: Promise<string> = getUploadUrl()
          .then((uploadResponse) => {
            setAssetUploadedPercentage(30);
            return uploadResponse;
          })
          .then((uploadResponse) => uploadToS3([file], uploadResponse.url)
              .then((value) => {
                // setUploadProgress({...uploadProgress, current: uploadProgress.current+1});
                setAssetUploadedPercentage(60);
                return uploadResponse.url;
              }))
          .then((uploadUrl) => createAsset(file, uploadUrl, userId).then((value) => {
            // setUploadProgress({...uploadProgress, current: uploadProgress.current+1});
            setAssetUploadedPercentage(100);
            return uploadUrl;
          }));
      filesPromises.push(singleAssetUpload);
    });

    Promise.all(filesPromises).then((values) => {
      console.log(values);
      setIsUploadingAssets(false);
    });
  };

  // const handleOk = async () => {
  //   setConfirmLoading(true);
  //
  //   const filesPromises: Array<Promise<string>> = [];
  //
  //   console.log('files to upload: ' + filesToUpload.length);
  //   // console.log('uploaded files' + uploadedFiles);
  //   // setUploadProgress({
  //   //   current: 0,
  //   //   total: filesToUpload.length,
  //   // });
  //   filesToUpload.map((file) => {
  //     const singleAssetUpload: Promise<string> = getUploadUrl()
  //         .then((uploadResponse) => {
  //           return uploadResponse;
  //         })
  //         .then((uploadResponse) => uploadToS3([file], uploadResponse.url)
  //             .then((value) => {
  //               return uploadResponse.url;
  //             }))
  //         .then((uploadUrl) => createAsset(file, uploadUrl, userId).then((value) => {
  //           return uploadUrl;
  //         }));
  //     filesPromises.push(singleAssetUpload);
  //   });
  //
  //   Promise.all(filesPromises).then((values) => console.log(values));
  //   setConfirmLoading(false);
  // };

  const handleChange: UploadProps['onChange'] = (info: any) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-10);
    newFileList = newFileList.map((file) => {
      return file;
    });
    setFilesToUpload(newFileList);
  };

  const propsUpload = {
    onRemove: () => setIsButtonEnable(false),
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    confirmLoading: isUploadingAssets,
    onchange: handleChange,
    multiple: true,
    beforeUpload(file: RcFile, fileList: RcFile[]) {
      console.log(file);

      setFilesToUpload(fileList);
      setIsButtonEnable(true);
      return false;
    },
  };
  return (
    <div {...props}>
      <Modal
        title="Upload Files"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        destroyOnClose={true}
      >

        <Upload {...propsUpload} itemRender={(originNode, file) => {
          return (
            <div key={file.uid}>
              {originNode}
            </div>
          );
        }}
        >
          <Button
            name="file"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',

            }}
            data-testid="uploadButton"
            type="link"
            icon={<UploadOutlined />}
          />

        </Upload>

        { filesToUpload.length > 0 &&
          <Progress percent={Math.round(assetUploadedPercentage)}/>
        }

        <Button
          name='startUploadButton'
          type="primary"
          onClick={handleOk}
          loading={isUploadingAssets}
          style={{marginTop: 16}}
          disabled={!isButtonEnable}
          data-testid="startUploadButton"
        >
          {isUploadingAssets ? 'Uploading' : 'Start Upload'}
        </Button>
        {isUploadingAssets &&
          <Spin tip="Loading" size="small">
            <div className="content" />
          </Spin>
        }

      </Modal>

      {profileTemp.email && (
        <Button
          type="link"
          onClick={showModal}
          icon={<UploadOutlined/>}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            float: 'left',
            paddingLeft: '2px',
            paddingRight: '0',
            paddingTop: '5px',
          }}
          data-testid="uploadAssetButton"
        />
      )}
    </div>
  );
};
export default LinkUpload;
