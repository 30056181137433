import {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import jwtDecode from 'jwt-decode';
import {UserProfile} from '../models/UserProfile';

// eslint-disable-next-line require-jsdoc
export function useUserProfile(): UserProfile {
  const [cookiesId] = useCookies(['id_token']);
  const [profile, setProfile] = useState<UserProfile>(new UserProfile());
  useEffect(() => {
    const idToken = cookiesId.id_token;
    if (idToken) {
      const decoded = jwtDecode<UserProfile>(idToken || '');
      setProfile(decoded);
      console.log(decoded);
    }
  }, [cookiesId]);
  return profile;
}
