import {ResponseApiSingle} from '../../../models/ResponseApi';
import axios from 'axios';
import {TokenResponse} from '../../../models/TokenResponse';
import * as Constants from '../../../utils/constants';

const domain = Constants.beServer;
export const getTokenFromCode:
  (code: string) => Promise<ResponseApiSingle<TokenResponse>> =
  async (code: string): Promise<ResponseApiSingle<TokenResponse>> => {
    const tokenWrapper =
      await axios.get(domain + '/v1/login/token?code=' + code);
    return tokenWrapper.data;
  };

export const login = (): void => {
  location.replace(domain+'/v1/login');
};

export const logout = (): void => {
  location.replace(domain+'/v1/logout');
};
