import React, {useEffect, useState} from 'react';
import './App.css';
import {Layout, Avatar, Popover, Card, Button} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import Sidebar from './components/Sidebar/Sidebar';
import {Asset} from './models/Asset';
import {downloadFile} from './utils/network/fileDownload/fileDownload';
import {useUserProfile} from './hooks/UseUserProfile';
import {login, logout} from './utils/network/auth/auth';
import {UserProfile} from './models/UserProfile';
import {useCookies} from 'react-cookie';
import {ResponseApiBulk} from './models/ResponseApi';
import {getAssetsByOwnerAndGroup} from './utils/network/folderReader';

const {Header, Content} = Layout;
const {Meta} = Card;

const App = (props: any) => {
  const [cookie, setCookieId, removeCookieId] = useCookies(['id_token']);
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
  const [cookiesAccess, setCookieAccess, removeCookieAccess] =
    useCookies(['access_token']);
  const logoutUser = () => {
    setProfile(new UserProfile());
    removeCookieAccess('access_token');
    removeCookieId('id_token');
    logout();
  };
  const loadMore = async (currentCursor: string) => {
    setLoadMoreLoading(true);
    const {data, cursor}: ResponseApiBulk<Asset> =
      await getAssetsByOwnerAndGroup(profile.email || '', '0', currentCursor);
    setLoadMoreLoading(false);
    setAssets(assets.concat([...data || []]));
    setCursor(cursor || '');
  };
  const content = (
    <div>
      <p>Settings</p>
      <a onClick={() => login()}>Login</a>
      <br/>
      <a onClick={() => logoutUser()}>Logout</a>
    </div>
  );
  const [assets, setAssets] = useState<Asset[]>([]);
  const [cursor, setCursor] = useState<string>('');
  const [profile, setProfile] = useState<UserProfile>(new UserProfile());

  const profileTemp = useUserProfile();
  console.log(profileTemp);
  useEffect(() => {
    setProfile(profileTemp);
  }, [profileTemp]);

  const onAssetsLoad = (assets: Asset[], cursor: string) => {
    console.log(assets);
    setAssets(assets || []);
    setCursor(cursor);
  };

  const getDownloadUrl = async (assetId: string | undefined) => {
    const downloadData = await downloadFile(assetId || '');
    window.open(downloadData.url, '_blank');
  };

  return (
    <Layout>
      <meta name="referrer" content="no-referrer" />
      <Header style={{background: '#659dbd',
        display: 'flex',
        flexDirection: 'row',
        height: '10%',
        padding: '0px'}}>
        <div style={{flexGrow: 1, padding: '0em 2em 0em 2em'}}>
          <img style={{'height': '60px',
            'padding': '0.1em'}} src={'/logo500.png'}/>
        </div>
        <div style={{flexGrow: 20}}/>
        <div style={{flexGrow: 1, margin: 'auto'}}>
          <Popover placement="bottom" content={content} trigger="click">
            <Avatar src={profile?.picture || 'https://joeschmoe.io/api/v1/random'} style={{
              backgroundColor: '#87d068',
              display: 'table',
            }}/>
          </Popover>
        </div>
      </Header>
      <Layout>
        <Sidebar onAssetsLoad={onAssetsLoad}/>
        <Content style={{
          height: '90vh',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          overflow: 'scroll',
        }}>
          <div style={{
            display: 'flex',
            padding: '10px',
            flexWrap: 'wrap',
            alignContent: 'flex-start',
            justifyContent: 'center',
          }}>
            {
              assets.map((value) => {
              // eslint-disable-next-line react/jsx-key
                let description = value.description;
                if (description && description?.length > 12) {
                  description = description?.slice(0, 12)+'...';
                }
                return <Card
                  key={value.ID}
                  hoverable
                  cover={<img
                    style={{
                      'margin': 'auto',
                      'width': '100px',
                      'height': '100px',
                      'objectFit': 'cover'}}
                    alt="example"
                    src={value.image_url} />}
                  style={{
                    width: 190,
                    height: 'max-content',
                    margin: '2vh',
                    borderRadius: '10px',
                    padding: '10px',
                    background: '#a9a9a9'}}
                >
                  <Popover
                    placement="bottomLeft"
                    title={'Actions'} content={(
                      <div>
                        <DownloadOutlined
                          type='link' onClick={() => getDownloadUrl(value.ID)}/>
                      </div>
                    )}
                    trigger="click">
                  ...
                  </Popover>
                  <Meta title={value.title}
                    description={description} />
                </Card>;
              })
            }
          </div>
          {cursor &&
          <Button type="primary"
            onClick={() => loadMore(cursor)}
            loading={loadMoreLoading}>load more...
          </Button>
          }
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
