import {createBrowserRouter} from 'react-router-dom';
import App from '../App';
import React from 'react';
import AuthManager from '../components/AuthManager/AuthManager';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
  },
  {
    path: '/login',
    element: <AuthManager/>,
  },
]);

export default router;
