import axios from 'axios';
import {GetUploadResponse} from './fileUpload.models';
import * as Constants from '../../../utils/constants';

const domain = Constants.beServer;

export const getUploadUrl = async (): Promise<GetUploadResponse> =>{
  return axios.get(domain+'/v1/assets/uploadUrl')
      .then((urlResponse) => urlResponse.data);
};

export const createAsset =
  // eslint-disable-next-line max-len
  async (file: File, urlToUpload: string, userId: string): Promise<void> => {
    if (userId.length > 0) {
      return axios.post(domain+'/v1/assets', {
        filename: file.name,
        ext: file.name.split('.').pop(),
        description: file.name,
        version_number: 1,
        group_id: '0',
        s3_pre_signed_url: urlToUpload,
        owner_id: userId,
      } );
    }
    return Promise.reject(new Error('userId cannot be empty'));
  };
