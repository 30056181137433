// eslint-disable-next-line max-len
// export const uploadToS3 = async (file:File[], url:string): Promise<boolean> =>{
//   if (!file || file.length === 0) {
//     console.log('File to upload is undefined');
//     return false;
//   }
//   // Send the file to File-system
//   console.log('Sending file to S3...');


//   return await new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       fetch(url, {
//         method: 'PUT',
//         body: reader.result,
//         headers: [],
//       }).then(() => {
//         resolve(true);
//       }).catch(() => {
//         reject(new Error('Error uploading image to S3'));
//       });
//     };
//     reader.onerror = () => {
//       reject(new Error('Image could not be read'));
//     };
//     reader.readAsArrayBuffer(file as File[]);
//   });
// };
// eslint-disable-next-line max-len
export const uploadToS3 = async (files: File[], url: string) => {
  if (!files || files.length === 0) {
    console.log('No files to upload');
    return false;
  }

  // Use Promise.all to wait for all files to upload
  await Promise.all(files.map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url, true);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            console.log(`Uploading file ${file.name}: ${progress}%`);
          }
        };
        xhr.onerror = () => {
          reject(new Error(`Error uploading image to S3: ${file.name}`));
        };
        xhr.onload = () => {
          resolve(true);
        };
        xhr.send(reader.result as ArrayBuffer);
      };
      reader.onerror = () => {
        reject(new Error(`Image could not be read: ${file.name}`));
      };
      reader.readAsArrayBuffer(file as unknown as File);
    });
  }));

  return true;
};
