import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {getTokenFromCode} from '../../utils/network/auth/auth';
import jwtDecode from 'jwt-decode';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

const AuthManager = (props:any) =>{
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState('');
  const [cookieAccess, setCookieAccess] = useCookies(['access_token']);
  const [cookieId, setCookieId] = useCookies(['id_token']);
  const navigate = useNavigate();
  useEffect(() => {
    getTokenFromCode(searchParams.get('code') || '')
        .then(({data}) => {
          const decoded = jwtDecode(data?.id_token || '');
          console.log(decoded);
          setToken(data?.id_token || '');
          setCookieAccess('access_token', data?.access_token);
          setCookieId('id_token', data?.id_token);
          navigate('/');
        });
  }, []);
  return (<div/>);
};

export default AuthManager;
