// eslint-disable-next-line require-jsdoc
export class UserProfile {
  // eslint-disable-next-line camelcase
  family_name: string | undefined
  // eslint-disable-next-line camelcase
  given_name: string | undefined
  email: string | undefined
  name: string | undefined
  nickname: string | undefined
  picture: string | undefined
}
